<template>
  <div class="register_finish">
    <svg-icon iconClass="success"></svg-icon>
    <span class="word_title">
      {{
        $t('register.finishPage.title', {
          individualUserName: individualUserName,
          platformName: $config.info.fullName
        })
      }}&nbsp;&nbsp;
    </span>
    <div class="text" v-if="account && idPassed"
      v-html="$t('register.finishPage.contentDefault', { account: account, email: email })">
    </div>

    <!-- id3沒過，有上傳文件，有帳號 -->
    <div class="text" v-else-if="account" v-html="$t('register.finishPage.contentWithoutId3WithAccount')"></div>

    <!-- id3沒過，有上傳文件，沒有帳號 -->
    <div class="text" v-else v-html="$t('register.finishPage.contentWithoutId3WithoutAccount')"></div>

    <!-- id3沒過，沒有上傳文件，有帳號 -->
    <!-- 跳轉HOME PAGE -->

    <!-- id3沒過，沒有上傳文件，沒有帳號 -->
    <!-- 跳轉HOME PAGE -->

    <div class="btn_box">
      <el-button v-if="account" @click="goDeposit" type="primary">
        {{ $t('register.btn.fundnow') }}
      </el-button>
      <el-button v-else @click="goHome" type="primary">
        {{ $t('common.button.bkToHm') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { apiID3Status } from '@/resource/register';
import { apiGetMetaTraderAccounts } from '@/resource';

export default {
  data() {
    return {
      account: '',
      idPassed: false
    };
  },
  mounted() {
    apiID3Status().then(resp => {
      if (resp.data.code == 0 && resp.data.data) {
        this.idPassed = true;
        this.$store.commit('common/setPendingIDProofApproval', false);
        this.$store.commit('common/setRequireIDProof', false);
      }
      if (this.requireIDProof && !this.idPassed) this.$router.push('/home');
    });
    apiGetMetaTraderAccounts().then(resp => {
      if (resp.data && resp.data.length == 1 && resp.data[0].mt4_account) {
        this.account = resp.data[0].mt4_account;
        this.$store.commit('common/setOpenAccountAction', true);
      }
    });
  },
  computed: {
    individualUserName() {
      return this.$store.state.common.individualUserName;
    },
    email() {
      return this.$store.state.common.email;
    },
    requireIDProof() {
      return this.$store.state.common.requireIDProof;
    }
  },
  methods: {
    goDeposit() {
      this.$router.push('/depositFunds');
    },
    goHome() {
      this.$router.push('/home');
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/register/authority.scss';
// @import '@/assets/css/pages/register/registerFinish.scss';
.register_finish {
  display: flex;
  flex-direction: column;
  align-items: center;

  .svg-icon {
    font-size: 48px;
    display: block;
    margin-bottom: 24px;
  }

  .text,
  .word_title {
    font-size: 14px;
    color: $text-primary;
    line-height: 22px;
  }

  .word_title {
    margin-bottom: 16px;
  }

  .btn_box {
    margin-top: 24px;
  }
}
</style>
